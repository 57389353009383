<template>
  <div class="">
    <DefaultList
      :url="url"
      :perPage="5"
      :showPagination="false"
      :showEmpty="false"
    >
      <template #item="{ item }">
        <NewsListItem :item="item" @click="newsItemClicked" />
      </template>
      <template #footer>
        <b-list-group-item class="text-primary" :to="`/places/${place.id}`">
          {{
            $t("app.home.news.more", { placeName: place.name })
          }}</b-list-group-item
        >
      </template>
    </DefaultList>
  </div>
</template>

<script>
import { Browser } from "@capacitor/browser";
import DefaultList from "@/components/DefaultList";
import NewsListItem from "@/components/NewsListItem";
export default {
  components: {
    DefaultList,
    NewsListItem,
  },
  props: {
    place: {
      type: Object,
    },
  },
  computed: {
    url() {
      return `/api/places/${this.place.id}/news-items`;
    },
  },
  methods: {
    /* istanbul ignore next */
    newsItemClicked(item) {
      Browser.open({ url: item.link_url });
    },
  },
};
</script>
