<template>
  <div
    class="news-item d-flex flex-row align-items-center position-relative px-3 py-2"
  >
    <div class="mr-3" style="max-width: 30px">
      <img
        :src="item.publisher_icon_url"
        class="img-fluid rounded"
        style="max-width: 30px; border-radius: 15px !important"
      />
    </div>
    <div>
      <div class="list-item-head">
        {{ item.published | moment("dd. DD.MM.YYYY") }}
      </div>
      <div class="list-item-title">{{ item.news_feed.publisher }}</div>
      <div class="list-item-detail">{{ item.content }}</div>
    </div>
    <a href="#" @click.prevent="newsItemClicked()" class="stretched-link"></a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    /* istanbul ignore next */
    newsItemClicked() {
      this.$emit("click", this.item);
    },
  },
};
</script>
