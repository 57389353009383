<template>
  <div class="section my-4">
    <div class="section-title" v-if="show">
      {{ $t("app.home.recyclingEvents") }}
    </div>
    <DefaultList
      url="/api/user/recycling-events"
      :showEmpty="false"
      @isEmptyChanged="(value) => (show = !value)"
      ref="list"
    >
      <template #item="data">
        <RecyclingEventListItem :item="data.item" :showStreet="true" />
      </template>
    </DefaultList>
  </div>
</template>

<script>
import DefaultList from "@/components/DefaultList";
import RecyclingEventListItem from "@/components/RecyclingEventListItem";
export default {
  components: {
    DefaultList,
    RecyclingEventListItem,
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>
