<template>
  <div class="">
    <DefaultList :url="url" :showEmpty="false">
      <template #item="data">
        <div class="weather-warning px-3 py-2">
          <div class="list-item-head" :id="`warning-date-${data.item.id}`">
            {{ data.item.start | moment("dd. DD.MM.YYYY HH:mm") }} Uhr -
            {{ data.item.end | moment("dd. DD.MM.YYYY HH:mm") }} Uhr
          </div>
          <div class="list-item-title">
            <a
              v-b-toggle="[`warning-detail-${data.item.id}`]"
              class="text-body"
              >{{ data.item.headline }}</a
            >
          </div>
          <b-collapse
            class="list-item-detail"
            :id="`warning-detail-${data.item.id}`"
          >
            {{ data.item.content }}
          </b-collapse>
        </div>
      </template>
    </DefaultList>
  </div>
</template>

<script>
import DefaultList from "@/components/DefaultList";

export default {
  components: {
    DefaultList,
  },
  props: {
    place: {
      type: Object,
    },
  },
  computed: {
    url() {
      return `/api/places/${this.place.id}/weather-warnings`;
    },
  },
};
</script>
