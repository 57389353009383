<template>
  <div class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <div class="section-title">
        {{ place.name }}
      </div>
      <div v-if="weather" class="pr-3">
        <img
          :src="weather.icon_url"
          :alt="weather.description"
          class="img-fluid rounded"
          style="max-width: 50px; margin: -5px 0"
        />
        {{ weather.temp.toFixed() }}°C
      </div>
    </div>
    <WeatherWarningList :place="place" class="mb-2" />
    <NewsList :place="place" class="mb-2" />
  </div>
</template>

<script>
import httpService from "@/services/HttpService";
import NewsList from "./NewsList.vue";
import WeatherWarningList from "./WeatherWarningList.vue";
import RefreshableMixin from "@/mixins/RefreshableMixin";

export default {
  mixins: [RefreshableMixin],
  components: { NewsList, WeatherWarningList },
  props: {
    place: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      weather: null,
    };
  },
  mounted() {
    this.isLoading = false;
    this.weather = null;
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.onRefreshingDataStarted();
      httpService
        .get(`/api/places/${this.place.id}/weather`, {
          suppressErrorToast: true,
          handleLoading: (isLoading) => (this.isLoading = isLoading),
        })
        .then((response) => {
          this.weather = response.data;
        })
        /* istanbul ignore next */
        .catch(/* istanbul ignore next */ () => /* istanbul ignore next */ {});
    },
  },
};
</script>
