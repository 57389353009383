<template>
  <DefaultPage :title="$t('app.user.home.title')">
    <div>
      <RecyclingEventList />
      <b-overlay :show="isLoading">
        <Place v-for="place in places" :key="place.id" :place="place" />
        <div
          v-if="onboardingAvailable"
          class="p-2 m-auto"
          style="max-width: 400px"
        >
          <b-button
            @click="startOnboarding"
            class="w-100"
            variant="primary"
            id="startOnboarding"
          >
            {{ $t("app.user.home.startOnboarding") }}</b-button
          >
        </div>
      </b-overlay>
    </div>
  </DefaultPage>
</template>

<script>
import httpService from "@/services/HttpService";
import Place from "../components/Place.vue";
import RecyclingEventList from "../components/RecyclingEventList.vue";
import DefaultPage from "@/components/DefaultPage";

export default {
  components: {
    DefaultPage,
    Place,
    RecyclingEventList,
  },
  data() {
    return {
      places: [],
      isBlank: true,
      isLoading: false,
    };
  },
  computed: {
    onboardingAvailable() {
      return !this.isBlank && !this.isLoading && this.places.length == 0;
    },
  },
  mounted() {
    this.loadPlaces();
  },
  methods: {
    loadPlaces() {
      const vm = this;
      httpService
        .get(`/api/user/places`, {
          handleLoading: (isLoading) => {
            this.isLoading = isLoading;
            this.isBlank = false;
          },
        })
        .then((response) => {
          vm.places = response.data.items;
        });
    },
    startOnboarding() {
      this.$router.replace("/user/onboarding");
    },
  },
};
</script>
